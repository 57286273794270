/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.bg-scl-primary {
  background-color: #263245;
}


.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  color: #fff;
  background-color: #163960;
}
